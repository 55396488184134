import React, { useState, useEffect } from 'react';

const TimeAdjustor = ({rowIndex, timespent, onChange}) => {


    const [time, setTime] = useState("00:00");

    useEffect(() => {
        setTime(timespent)
    }, [timespent])

    const incrementTime = () => {
        let [hours, minutes] = time.split(":").map(Number);
        minutes += 15;
        if (minutes >= 60) {
            hours = (hours + 1) % 24;
            minutes -= 60;
        }
        let timefformed = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
        setTime(timefformed);
        onChange(timefformed, (hours*60)+minutes)
    };

    const decrementTime = () => {
        let [hours, minutes] = time.split(":").map(Number);
        minutes -= 15;
        if (minutes < 0) {
            hours = (hours - 1 + 24) % 24;
            minutes += 60;
        }
        setTime(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
    };

    return (
        
        <div style={{border:'1px solid lightgrey',padding:'5px 14px',borderRadius:'5px',backgroundColor:'white'}}>
           { 
           (time) && 
            <>
                <button style={{width:'16px',height:'100%',fontWeight:'bold',background:'none'}} onClick={incrementTime}>+</button>
                <input
                    // type="time"
                    style={{width:'60%',border:'none',textAlign:'center',fontSize:'14px'}}
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                />
                <button style={{width:'20px',height:'100%',fontWeight:'bold',background:'none'}} onClick={decrementTime}>-</button>
            </>
            }
        </div>
    );
};

export default TimeAdjustor;
