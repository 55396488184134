export const getFileNameFromHeader = (response) => {
    let headerLine = response.headers['content-disposition'];
    let startFileNameIndex = headerLine.indexOf('"') + 1
    let endFileNameIndex = headerLine.lastIndexOf('"');
    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

    return filename
};

export async function getImageToBase64(file) {

    return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject(error);
        };

    });
}

export function swapArrayElemPos(arr, index1, index2) {
    var temp = arr[index1];

    arr[index1] = arr[index2];
    arr[index2] = temp;
}