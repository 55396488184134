import React from 'react'
import { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom';

const useUnsavedWarning = (message = "You have unsaved data. Are you sure you want to leave this page?") => {
    const [isDirty, setIsDirty] = useState(false)

    // detecting when user close the browser

    useEffect(() => {
        window.onbeforeunload = isDirty && (() => message);

        return () => {
            window.onbeforeunload = null
        };
    }, [isDirty]);

    const setIsDirtyValue = (value) => {
        setIsDirty(value)
    }

    // detecting when user navigate between routes

    const routerPrompt = <Prompt when={isDirty} message={message} />

    return [routerPrompt, () => setIsDirtyValue(true), () => setIsDirtyValue(false)]


}
export default useUnsavedWarning