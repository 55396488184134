import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state) => state.clients || initialState;

export const selectClients = createSelector(
  [selectDomain],
  clientsState => clientsState,
);
