import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions } from './slice';
import { post, request, requestAndCompleteResponse } from '../../../utils/request';
import { delayedRedirect } from '../../../utils/response';
import Toast from '../../../services/toastService';
import FileDownload from 'downloadjs';
import _ from 'lodash';
import queryString from 'query-string';
import { getFileNameFromHeader } from '../../../utils/functions';


export function* fetchAdminSignedContract(action) {
  yield put(actions.signedContractLoading());
  const { page, limit } = action.payload;
  try {
    const data = yield call(
      request,
      `signed-contract/paginated?page=${page ? page : 1}&limit=${limit}`,
    );

    yield put(actions.signedContractSuccess((data.signedContracts) ? data.signedContracts : []));
  } catch (e) {
    yield put(actions.signedContractFailure(e.toString()));
    Toast.error('Some error occurred while getting signed contracts');
  }
}

export function* fetchAdminSignedContractById(action) {

  yield put(actions.signedContractByIdLoading());

  try {
    const data = yield call(
      request,
      `signed-contract/${action.payload.id}`,
    );

    yield put(actions.signedContractByIdSuccess(data.signedContract));
  } catch (e) {
    yield put(actions.signedContractByIdFailure(e.toString()));
    Toast.error('Some error occurred while getting signed contract');
  }
}


export function* saveSignedContract(action) {

  yield put(actions.signedContractByIdLoading());

  try {
    const data = yield call(
      request,
      `signed-contract`,
      {
        method: 'post',
        data: action.payload.data,
      },
    );

    yield put(actions.saveSignedContractSuccess(data));

    Toast.success('Successfully saved signed Contract');
    yield call(delayedRedirect, '/admin/add_applications', 2000);
  } catch (e) {
    yield put(actions.signedContractByIdFailure(e.toString()));
    Toast.error('Some error occurred while saving signed Contract');
  }
}

// update signed contract

export function* updateSignedContract(action) {
  yield put(actions.updateSignedContractLoading());

  try {
    const data = yield call(
      request,
      `signed-contract/${action.payload.id}`,
      {
        method: 'patch',
        data: action.payload.data,
      },
    );
    yield put(actions.updateSignedContractSuccess(data));
    Toast.success('Successfully saved signed contract');
  } catch (e) {
    yield put(actions.updateSignedContractFailure(e.toString()));
    Toast.error('Some error occurred while saving signed contract');
  }
}

// delete signed contract 

export function* deleteSignedContact(action) {
  yield put(actions.deleteSignedContactLoading());

  try {
    yield call(
      request,
      `/signed-contract/${action.payload.id}`,
      {
        method: 'delete',
      },
    );
    yield put(actions.deleteSignedContactSuccess({ id: action.payload.id }));
    Toast.success('Successfully deleted signed contract');
  } catch (e) {
    yield put(actions.deleteSignedContactFailure(e.toString()));
    Toast.error('Some error occurred while deleting contract');
  }
}

// Meta Data List

export function* fetchMetaDataList(action) {
  // if (!action.payload.type) {
    yield put(actions.metaDataListLoading());
  // }
  const { page } = action.payload;

  const filterQueryParams = { ...action.payload.filterQueryParams };
  Object.assign(filterQueryParams);
  const queryStringed = queryString.stringify(_.pickBy(filterQueryParams, _.identity));
  try {
    const data = yield call(
      request,
      `signed-contract/meta-data?${queryStringed}&page=${page ? page : 1}`,
      {
        method: 'POST',
      }
    );
    const finalResult = {
      data: data.data ? data.data : [],
      type: action.payload.type ? action.payload.type : ''
    }

    //console.log(finalResult)
    yield put(actions.metaDataListSuccess(finalResult));
    if (action.payload.successCallback) {
      action.payload.successCallback(finalResult.data)
    }
  } catch (e) {
    yield put(actions.metaDataListFailure(e.toString()));
    console.log("e ==", e)
    //Toast.error(`Some error occurred while getting signed contracts ${e.toString()}`);
  }
}

export function* fetchSignedContractDataList(action) {
  // if (!action.payload.type) {
    yield put(actions.signedContractDataListLoading());
  // }
  const { page } = action.payload;

  const filterQueryParams = { ...action.payload.filterQueryParams };

  Object.assign(filterQueryParams);
  let queryStringed = queryString.stringify(_.pickBy(filterQueryParams, _.identity));
  // queryStringed = queryStringed.replaceAll('Renewals=', "Renewals[]=")

  try {
    const data = yield call(
      request,
      `signed-contract/signed-contract?${queryStringed}&page=${page ? page : 1}`,
      {
        method: 'POST'
      }
    );
    const finalResult = {
      data: data.data ? data.data : [],
      type: action.payload.type ? action.payload.type : ''
    }

    //console.log(finalResult)
    yield put(actions.signedContractDataListSuccess(finalResult));
    if (action.payload.successCallback) {
      action.payload.successCallback(finalResult.data)
    }
  } catch (e) {
    yield put(actions.signedContractDataListFailure(e.toString()));
    //Toast.error(`Some error occurred while getting signed contracts ${e.toString()}`);
  }
}

export function* fetchAllUsedSupportingFields(action) {
  //yield put(actions.allUsedSupportingFieldsLoading());
  try {
    const data = yield call(
      request,
      `signed-contract/supporting-data`,
    );

    yield put(actions.allUsedSupportingFieldsSuccess((data.data) ? data.data : []));
  } catch (e) {
    yield put(actions.allUsedSupportingFieldsFailure(e.toString()));
    //Toast.error('Some error occurred while getting signed contracts');
  }
}

export function* fetchSignedContractSupportingFields(action) {
  //yield put(actions.allUsedSupportingFieldsLoading());
  try {
    const data = yield call(
      request,
      `signed-contract/completed-supporting-data`,
    );

    yield put(actions.signedContractSupportingFieldsSuccess((data.data) ? data.data : []));
  } catch (e) {
    yield put(actions.signedContractSupportingFieldsFailure(e.toString()));
    //Toast.error('Some error occurred while getting signed contracts');
  }
}

export function* getMetaFieldSelection(action) {
  yield put(actions.getMetaFieldSelectionLoading());
  try {
    const data = yield call(
      request,
      `users/metadata-fields-selection`,
    );

    yield put(actions.getMetaFieldSelectionSuccess(data));
    // Toast.success('Successfully saved settings');
  } catch (e) {
    yield put(actions.getMetaFieldSelectionFailure(e.toString()));
    //Toast.error('Some error occurred while getting signed contracts');
  }
}

export function* updateMetaFieldSelection(action) {
  yield put(actions.updateMetaFieldSelectionLoading());
  try {
    const data = yield call(
      request,
      `users/update-metadata-fields-selection`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );

    yield put(actions.updateMetaFieldSelectionSuccess());
    if (action.payload.successCallback) {
      action.payload.successCallback()
    }
  } catch (e) {
    yield put(actions.updateMetaFieldSelectionFailure(e.toString()));
    Toast.error('Some error occurred while saving changes');
  }
}

export function* updateSignedContractFieldSelection(action) {
  yield put(actions.updateSignedContractFieldSelectionLoading());
  try {
    const data = yield call(
      request,
      `users/update-signed-metadata-fields`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );

    yield put(actions.updateSignedContractFieldSelectionSuccess());
    if (action.payload.successCallback) {
      action.payload.successCallback()
    }
  } catch (e) {
    yield put(actions.updateSignedContractFieldSelectionFailure(e.toString()));
    Toast.error('Some error occurred while saving changes');
  }
}

export function* downloadSampleFile(action) {
  // yield put(actions.downloadLegalRequestDocumentLoading());

  try {
    const response = yield call(
      requestAndCompleteResponse,
      `signed-contract/download-user-sample-csv/${action.payload.userId}?newWorkflow=1`,
      {
        responseType: 'blob',
      },
    );
    yield call(FileDownload, response.data, 'uploadmatterandartefact.csv');
    Toast.success(`Successfully downloaded document`);
  } catch (e) {
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while downloading: ${e.message}`);
  }
}

export function* downloadSampleStepFile(action) {
  // yield put(actions.downloadLegalRequestDocumentLoading());

  try {
    const response = yield call(
      requestAndCompleteResponse,
      `signed-contract/download-workflow-steps-csv/${action.payload.userId}`,
      {
        responseType: 'blob',
      },
    );
    yield call(FileDownload, response.data, 'uploadworkflowsteps.csv');
    Toast.success(`Successfully downloaded document`);
  } catch (e) {
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while downloading: ${e.message}`);
  }
}

export function* uploadCsv(action) {
  yield put(actions.uploadZipLoading());

  try {
    const response = yield call(
      request,
      `signed-contract/artefact-matter-import/${action.payload.userId}`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    //download response file
    const url = window.URL.createObjectURL(
      new Blob([response]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `upload_response.csv`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    yield put(actions.uploadZipSuccess());
    Toast.success(`Successfully uploaded file`);
  } catch (e) {
    yield put(actions.uploadZipError(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while uploading: ${e.message}`);
  }
}

export function* uploadZip(action) {
  yield put(actions.uploadZipLoading());

  try {
    const response = yield call(
      request,
      `signed-contract/workflow-steps-zip-upload/${action.payload.userId}`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    //download response file
    const url = window.URL.createObjectURL(
      new Blob([response]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `upload_response.csv`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    yield put(actions.uploadZipSuccess());
    Toast.success(`Successfully uploaded file`);
  } catch (e) {
    yield put(actions.uploadZipError(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while uploading: ${e.message}`);
  }
}

export function* signedContractSaga() {

  yield takeLatest(actions.saveSignedContract.type, saveSignedContract);
  yield takeLatest(actions.fetchAdminSignedContract.type, fetchAdminSignedContract);
  yield takeLatest(actions.fetchAdminSignedContractById.type, fetchAdminSignedContractById);
  yield takeLatest(actions.updateSignedContract.type, updateSignedContract);
  yield takeLatest(actions.deleteSignedContact.type, deleteSignedContact);
  yield takeLatest(actions.fetchMetaDataList.type, fetchMetaDataList);
  yield takeLatest(actions.fetchAllUsedSupportingFields.type, fetchAllUsedSupportingFields);
  yield takeLatest(actions.updateMetaFieldSelection.type, updateMetaFieldSelection);
  yield takeLatest(actions.getMetaFieldSelection.type, getMetaFieldSelection);
  yield takeLatest(actions.fetchSignedContractDataList.type, fetchSignedContractDataList);
  yield takeLatest(actions.updateSignedContractFieldSelection.type, updateSignedContractFieldSelection);
  yield takeLatest(actions.fetchSignedContractSupportingFields.type, fetchSignedContractSupportingFields);
  yield takeLatest(actions.downloadSampleFile.type, downloadSampleFile);
  yield takeLatest(actions.uploadZip.type, uploadZip);
  yield takeLatest(actions.uploadCsv.type, uploadCsv);
  yield takeLatest(actions.downloadSampleStepFile.type, downloadSampleStepFile);
}
