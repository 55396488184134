import { Col, Dropdown, FlexboxGrid, Row } from 'rsuite';
import SearchInput from '../SearchInput';
import './styles.less';
import IconBurgerMenu from './../../../SVGIcons/SVG/components/IconBurgerMenu';
import IconSettings from './../../../SVGIcons/SVG/components/IconSettings';
import IconNotifications from './../../../SVGIcons/SVG/components/IconNotifications';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { request } from '../../../../../utils/request';
import { actions } from './../../../../shared-redux/Auth/slice';
import { useDispatch } from 'react-redux';
import userService from '../../../../../services/userService';
import { actions as userActions } from '../../../../shared-redux/User/slice';
import { hasPermissionsToViewPage } from '../../../../../services/authService';
import { stripTags } from '../../../../../utils/stringUtility'
import SwitchSubscriberModal from '../../../SwitchSubscriberModal'
import _ from 'lodash';

const PageHeader = (props) => {

  const searchSuggestion = [
    {
      id: "Dashboard",
      value: "Dashboard",
      content: (
        <a href={`/`} key={"Dashboard"}>
          <strong>{"Dashboard"}</strong>
        </a>
      )
    },
    {
      id: "New Matter",
      value: "New Matter",
      content: (
        <a href={`/new-matter`} key={"New Matter"}>
          <strong>{"New Matter"}</strong>
        </a>
      )
    },
    {
      id: "Resources",
      value: "Resources",
      content: (
        <a href={`/client-resources`} key={"Resources"}>
          <strong>{"Resources"}</strong>
        </a>
      )
    },
    {
      id: "Integrations",// "Workflow Management",
      value: "Integrations", //"Workflow Management",
      content: (
        <a href={`/integrations`} key={"Workflow Management"}>
          <strong>{"Integrations"}</strong>
        </a>
      )
    },
    {
      id: "How to use LightHub",
      value: "How to use LightHub",
      content: (
        <a href={`/how-to-use-lighthub`} key={"How to use LightHub"}>
          <strong>{"How to use LightHub"}</strong>
        </a>
      )
    }
  ]

  // const subscribers = [
  //   {
  //     name : 'Paul',
  //     userId : 0,
  //     isActive: false
  //   },
  //   {
  //     name : 'Adam',
  //     userId : 1,
  //     isActive: true
  //   },
  //   {
  //     name : 'Liberty',
  //     userId: 2,
  //     isActive: false
  //   }
  // ]
  const [subscribers, setSubscribers] = useState([])
  const [searchData, setSearchData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLighthubData, setSearchLighthubData] = useState([]);
  const [searchLighthubQuery, setSearchLighthubQuery] = useState('');
  const [currentParent, setCurrentParent] = useState('')

  const dispatch = useDispatch();

  const [user, setUser] = useState({});
  const [showSwitchSubscriberModal, setShowSwitchSubscriberModal] = useState(false)

  useEffect(() => {
    userService.getUser().then((value) => {
      setUser(value);
    });
    userService.getClientId().then((value) => {
      setCurrentParent(value);
    });
    dispatch({
      type: userActions.fetchSubscriberList.type,
      payload : {
        successCallback : (response) => {
          // console.log("response ==", response)
         setSubscribers(response)
        }
      }
    });
  }, []);

  const onSearch = useCallback(async (search) => {
    if (typeof search === 'object') {
      return;
    }
    setSearchQuery(search);
    try {
      const result = await request(`learned-friend/content/search/${search}`);
      const temp = result.contents.map((data, index) => ({
        content: (
          <a href={`/mylearnedfriend${data.category}/${data.slug}?query=${search}`} key={index}>
            {data.category.replace(/\//g, ' > ').replace(/-/g, ' ').toLowerCase()} > {data.title}
          </a>
        )
      }));
      setSearchData(temp);
    } catch (e) {
      setSearchData([]);
    }
  }, [searchData]);

  const onSearchLighthub = useCallback(async (search) => {
    if (typeof search === 'object') {
      return;
    }
    setSearchLighthubQuery(search);
    if (search.length < 1) {
      setSearchLighthubData([]);
      return
    }
    try {

      const match = searchSuggestion.filter((x) => x.value.toLowerCase().includes(search.toLowerCase()))

      // const result = await request(`users/search//${search}`);
      // const tempUserSearch = result.contents.users.map((user, index) => ({
      //   content: (
      //     <a href={`/clients/${user.parent ? user.parent : user._id}${user.parent ? `?subuser=${user._id}` : ''}`} key={index}>
      //       <strong>{user.name}</strong> in users
      //     </a>
      //   )
      // }));

      // const tempLegalRequestSearch = result.contents.entityAccessRights.map((legalRequest, index) => ({
      //   content: (
      //     <a href={`/?request=${legalRequest.legalRequestId}`} key={index}>
      //       <strong>{legalRequest.requestType}</strong> in legal requests
      //     </a>
      //   )
      // }));

      //const temp = [...tempUserSearch, ...tempLegalRequestSearch];

      setSearchLighthubData(match);
    } catch (e) {
      setSearchLighthubData([]);
    }
  }, [searchLighthubData]);

  const onLogout = () => {
    dispatch({
      type: actions.logoutUser.type,
    });
  };

  const onSwitchAccount = () => {
    //show switch account modal
    setShowSwitchSubscriberModal(true)
  }

  const onSubscriberChange = (selectedParent) => {
    setShowSwitchSubscriberModal(false)
    if(selectedParent !== currentParent){
      dispatch({
        type : userActions.selectSubscriber.type,
        payload : {
          data: {
            subscriber: selectedParent,
            successCallback : (response)  => {
              // console.log("response on switcch = ", response)
             window.location.reload(true);
            }
          }
        }
      })
    }
  }

  const markRead = (activityId) => {
    dispatch({
      type: userActions.markNotificationRead.type,
      payload: {
        activityId,
        userId: user.id,
      }
    });
  };

  return (
    <div className="page-header">
      <FlexboxGrid align="middle">

        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6} mdPush={2}>
          <SearchInput
            placeholder="Search LightHub..."
            tooltip="Search LightHub"
            data={searchLighthubData}
            onChange={(value) => onSearchLighthub(value)}
            onBlur={() => { if (searchLighthubQuery === '') setSearchLighthubData([]) }}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6} mdPush={2}>
          <SearchInput
            placeholder="Search My Learned Friend..."
            data={searchData}
            onChange={(value) => onSearch(value)}
            onBlur={() => { if (searchQuery === '') setSearchData([]) }}
            tooltip="Search My Learned Friend"
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} colspan={24} md={2} mdPush={2}>
          <div className="icon-menu-wrapper">
            <Link to={'/mylearnedfriend'}>
              <IconBurgerMenu className="menu-icon" />
            </Link>
          </div>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item className="ml-auto" componentClass={Col} colspan={24} md={6}>
          <div className="right-menu-wrapper" >
            {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'SETTINGS') && <div className="icon-menu-wrapper" >
              <Link to={'/settings'}>
                <IconSettings className="menu-icon align-middle" />
              </Link>
            </div>}

            {/* <div className="icon-menu-wrapper" >
              <Dropdown
                placement="bottomEnd"
                className="notif-dropdown"
                title={<><IconNotifications className="menu-icon align-middle" />{props.notificationCount}</>}
                noCaret
              >
                {
                  props.notifications && props.notifications.map((notification, index) => {
                  
                    return (
                      <Dropdown.Item key={index}
                        renderItem={() =>
                          <Row className="pt1 tb1">
                            <Col md={24}>
                              <div className="d-flex">
                              <span  className={`w-80 ${notification.readStatus ? "" : "text-bold"}`}>{stripTags(notification.summary)}</span>
                              <span className="right" style={{ cursor: "pointer" }} onClick={!notification.readStatus ? () => markRead(notification._id) : () => { }}>{notification.readStatus ? "Read" : <strong>Unread</strong>}</span>
                              </div>
                            </Col>
                          </Row>
                        } />

                    );
                  })
                }
                {props.notifications.length === 0 && <Dropdown.Item disabled>No notifications</Dropdown.Item>}
                {props.notifications.length < props.notificationCount && <div className="rs-dropdown-item loadmore" onClick = {() => props.setPage()}><span>Load more</span></div>}
              </Dropdown>
            </div> */}

            <div className="user-menu-wrapper">

              <Dropdown title={user.name}>
                {/* <Dropdown.Item componentClass={'span'}>
                  <Link to="/profile">Profile</Link>
                </Dropdown.Item> */}
                <Dropdown.Item componentClass={'span'}>
                  <Link to="/profile">Profile</Link>
                </Dropdown.Item>
                <Dropdown.Item componentClass={'span'} onClick={onSwitchAccount}>
                  Switch Account
                </Dropdown.Item>
                <Dropdown.Item componentClass={'span'} onClick={onLogout}>
                  Logout
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    <SwitchSubscriberModal currentParent = {currentParent} subscribers = {subscribers} isVisible={showSwitchSubscriberModal} onClose = {() => setShowSwitchSubscriberModal(false)} onSave = {(selectedParent) => { onSubscriberChange(selectedParent)}}/>
    </div>
  );
};

export default PageHeader;