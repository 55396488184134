import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { actions as authActions } from './../Auth/slice';
import { request } from '../../../utils/request';
import Toast from '../../../services/toastService';
import userService from '../../../services/userService';
import { removeTheme, saveTheme } from '../../../services/themeService';
import paths from '../../../config/paths';
import { isAdmin } from '../../../services/authService';
import jwt_decode from "jwt-decode";

export function* changePassword(action) {
  yield put(actions.changePasswordLoading());

  try {
    yield call(
      request,
      `users/password-change/${action.payload.id}`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );
    yield put(actions.changePasswordSuccess());
    Toast.success('Successfully changed password');
    Toast.success('Please login again!');
    yield put(authActions.logoutUser());
  } catch (e) {
    yield put(actions.changePasswordFailure(e.toString()));
    if (e.response) {
      Toast.error(`Error: ${e.response.data.message}`);
      return;
    }
    Toast.error(`Error: ${e.toString()}`);
  }
}

export function* fetchUsers(action) {
  yield put(actions.usersLoading());

  try {
    const data = yield call(
      request,
      'users/all',
    );
    yield put(actions.usersSuccess(data.users));
  } catch (e) {
    yield put(actions.usersFailure(e.toString()));
    Toast.error('Some error occurred while getting users');
  }
}

export function* fetchUserNotifications(action) {
  //yield put(actions.userNotificationsLoading());

  try {
    const data = yield call(
      request,
      `user-activity/${action.payload.userId}?page=${action.payload.page}&limit=10`,
    );
    yield put(actions.userNotificationsSuccess(data.userActivities));
  } catch (e) {
    yield put(actions.userNotificationsFailure(e.toString()));
    Toast.error('Some error occurred while getting user notifications');
  }
}

export function* fetchLegalRequestUsers(action) {
  yield put(actions.fetchLegalRequestUsersLoading());

  try {
    const data = yield call(
      request,
      `users/assignment-users/${action.payload.legalRequestId}`,
    );
    yield put(actions.fetchLegalRequestUsersSuccess(data.users));
  } catch (e) {
    yield put(actions.fetchLegalRequestUsersFailure(e.toString()));
    Toast.error('Some error occurred while getting subusers');
  }
}


export function* markNotificationRead(action) {
  yield put(actions.markNotificationReadLoading());
  try {
    const data = yield call(
      request,
      `user-activity/${action.payload.activityId}`,
      {
        method: "PUT",
      }
    );
    yield put(actions.markNotificationReadSuccess(action.payload.activityId));
    yield call(fetchUserNotifications, { payload: { userId: action.payload.userId } });
  } catch (e) {
    yield put(actions.markNotificationReadFailure(e.toString()));
    Toast.error('Some error occurred while setting notification status');
  }
}

export function* fetchSubscriberList(action) {
  //yield put(actions.fetchLegalRequestUsersLoading());

  try {
    const data = yield call(
      request,
      `users/subscribers-list`,
    );
    yield put(actions.fetchSubscriberListSuccess(data.subscribers));
    action.payload.successCallback(data.subscribers)
  } catch (e) {
    //yield put(actions.fetchSubscriberListFailure(e.toString()));
    Toast.error('Some error occurred while getting subusers');
  }
}

export function* selectSubscriber(action) {
  yield put(actions.selectSubscriberLoading());

  try {
    const data = yield call(
      request,
      `users/select-subscriber`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );
    yield put(actions.selectSubscriberSuccess());

    Toast.success('Successfully changed subscriber');
    yield call(userService.saveUserToken, data.tokens.access, 'access');
    yield call(userService.saveUserToken, data.tokens.refresh, 'refresh');
    yield call(userService.saveUser, data.user);
    console.log("permissions =", data.user)

    let themeData = {};
    var decodedToken = jwt_decode(data.tokens.access.token);
    console.log("token par =", decodedToken.par)
    const isUserAdmin = yield call(isAdmin);

    // apply theme
    if (!isUserAdmin) {

      themeData = {
        primaryColor: data.user.branding.primaryColor,
        secondaryColor: data.user.branding.secondaryColor,
        textColor: data.user.branding.textColor,
        logo: data.user.branding.logo.image !== "" ? `${paths.serverPublicUrl}${data.user.branding.logo.path}${data.user.branding.logo.image}` : null,
        favicon: data.user.branding.favicon.image !== "" ? `${paths.serverPublicUrl}${data.user.branding.favicon.path}${data.user.branding.favicon.image}` : null,
      };

      yield call(saveTheme, 'theme', themeData);
      action.payload.data.successCallback(data)

    }

  } catch (e) {
    yield put(actions.selectSubscriberFailure(e.toString()));
    if(e.response && e.response.status === 403) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while switching subscriber');
  }
}

export function* userSaga() {
  yield takeLatest(actions.changePassword.type, changePassword);
  yield takeLatest(actions.fetchUsers.type, fetchUsers);
  yield takeLatest(actions.fetchUserNotifications.type, fetchUserNotifications);
  yield takeLatest(actions.fetchLegalRequestUsers.type, fetchLegalRequestUsers);
  yield takeLatest(actions.markNotificationRead.type, markNotificationRead);
  yield takeLatest(actions.fetchSubscriberList.type, fetchSubscriberList);
  yield takeLatest(actions.selectSubscriber.type, selectSubscriber);
}
