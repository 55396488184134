/**
 *
 * Asynchronously loads the component for AdminFormBuilder
 *
 */

 import { lazyLoad } from '../../../utils/loadable';

 export const WorkflowAdminBuilder = lazyLoad(
   () => import('./index'),
   module => module.WorkflowAdminBuilder,
 );
 