/**
 *
 * Asynchronously loads the component for ManageCounterParty
 *
 */

 import { lazyLoad } from '../../../utils/loadable';

 export const ManageCounterParty = lazyLoad(
   () => import('./index'),
   module => module.ManageCounterParty,
 );
 