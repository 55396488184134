import { useCallback, useEffect, useState } from 'react';
import './styles.less';
import { parseHtmlReBuildContent } from "../../../utils/stringUtility";

const TextAreaInput = (props) => {
    const {
        inlineLabel,
        stackedLabel,
        inputOptions,
        label,
        transparent,
        rounded,
        noLabel,
        inputClassName,
        borderless,
        labelStyle
    } = props;
    const [charCount, setCharCount] = useState(0);

    useEffect(() => {
        setCharCount(props.inputOptions.value ? props.inputOptions.value.length : 0);
    }, [props.inputOptions.value]);

    const handleOnChange = (e) => {
        if (props.charLimit) {
            console.log("exxecuted");
            e.target.value = e.target.value.slice(0, props.charLimit);
            props.inputOptions.onChange(e);
        } else {
            props.inputOptions.onChange(e);
        }
    };

    return (
        <div className={`form-input-group${props.inlineLabel ? ' label-inline' : ''}${props.stackedLabel ? ' label-stacked' : ''}${typeof props.stackedLabel === 'undefined' && typeof props.inlineLabel === 'undefined' ? ' label-inline' : ''}`} >
            {!props.noLabel && <label style={props.labelStyle} className="label-inline-display"><div dangerouslySetInnerHTML={{ __html: parseHtmlReBuildContent(label) }} /> {(props.isMandatory == "true" || props.isMandatory == true) && <span style={{ color: "#F62626" }}>*</span>}</label>}
            <textarea {...props.inputOptions} onChange={(e) => handleOnChange(e)} className="form-input">{props.inputOptions.value}</textarea>
            {props.charLimit && <div>{charCount}/{props.charLimit ? props.charLimit : 0}</div>}
        </div>
    );
};

TextAreaInput.getInitialProps = (ctx) => ({
    inlineLabel: true,
});

export default TextAreaInput;