import localforage from "localforage";
import _ from "lodash";
import jwt_decode from "jwt-decode";

const saveUserToken = async (token, type) => {
    localforage.setItem(`auth${_.startCase(type)}`, token);
};

const removeUserToken = async (type) => {
    await localforage.removeItem(`auth${_.startCase(type)}`);
};

const saveUser = async (data) => {
    const temp = { ...data };
    if (_.has(temp, 'subusers'))
        delete temp['subusers'];

    localforage.setItem('usr', temp);
};

const isSubUser = async () => {
    const user = await getUser();
    if (user && _.has(user, 'parent') && user.parent.length > 0 && !user.isGuestUser)
        return true;
    return false;
};

const removeUser = async () => {
    await localforage.removeItem('usr');
};

const getUser = async () => {
    return await localforage.getItem('usr');
};

const getClientId = async () => {
    const user = await localforage.getItem('usr');
    const accessToken = await localforage.getItem(`auth${_.startCase('access')}`);
    var decodedToken = jwt_decode(accessToken.token);
 
   // console.log("decoded ==", decodedToken);
    if (!user) {
        return null;
    }
    // if (_.has(user, 'parent') && !_.isEmpty(user.parent)) {
    //     return user.parent;
    // }
    //return user.id;
    return decodedToken.par // return current parent or self.
};

const getCurrentUserId = async () => {
    const user = await localforage.getItem('usr');
    if (!user) {
        return null;
    }
    return user.id;
};

const getUserToken = async (type) => {
    const accessToken = await localforage.getItem(`auth${_.startCase(type)}`);
    return accessToken;
};

const setIsOTPVerification = async (required, verified = false) => {
    localforage.setItem('verification', {
        isOTPVerificationRequired: required,
        isVerified: verified,
    });
};

const deleteIsOTPVerification = async (required, verified = false) => {
    await localforage.removeItem('verification');
};

const getOTPVerification = async () => {
    const verification = await localforage.getItem('verification');
    return verification;
};

const setIsOTPSentOnPageLoad = async () => {
    localforage.setItem('isOTPSentOnPageLoad', true);
};

const getIsOTPSentOnPageLoad = async () => {
    return await localforage.getItem('isOTPSentOnPageLoad');
};

const deleteIsOTPSentOnPageLoad = async () => {
    await localforage.removeItem('isOTPSentOnPageLoad');
};

const getUserTools = async () => {
    const user = await localforage.getItem('usr');
    if (!user) {
        return [];
    }
    if (_.has(user, 'parent') && !_.isEmpty(user.parent) && user.parent.length > 0) {
        return user.tools;
    }
    return user.tools;
};

const getUserIntegrations = async () => {
    const user = await localforage.getItem('usr');
    if (!user) {
        return [];
    }
    if (_.has(user, 'parent') && !_.isEmpty(user.parent) && user.parent.length > 0) {
        return user.integrations;
    }
    return user.integrations;
}

const getStripeCustomerId = async () => {
    const user = await localforage.getItem('usr');
    if (!user) {
        return null;
    }
    if (_.has(user, 'parent') && !_.isEmpty(user.parent) && user.parent.length > 0) {
        return user.stripeCustomerId;
    }
    return user.stripeCustomerId;
};

const userService = {
    saveUserToken,
    removeUserToken,
    setIsOTPVerification,
    getOTPVerification,
    saveUser,
    isSubUser,
    removeUser,
    getClientId,
    getCurrentUserId,
    getUserToken,
    getUserTools,
    getUserIntegrations,
    getUser,
    getStripeCustomerId,
    setIsOTPSentOnPageLoad,
    getIsOTPSentOnPageLoad,
    deleteIsOTPSentOnPageLoad,
    deleteIsOTPVerification,
};
export default userService;