import _ from "lodash";
import { useEffect, useState, useCallback, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Col, FlexboxGrid, Icon, IconButton, Panel, Row, Table, Toggle, Dropdown, Button, Grid } from "rsuite";
import TablePagination from "rsuite/lib/Table/TablePagination";
import Layout from "../../components/Layout";
import SelectInput from "../../components/SelectInput";
import SelectInputNoSearch from "../../components/SelectInputNoSearch"
import { clientsSaga } from "../../shared-redux/Clients/saga";
import { selectClients } from "../../shared-redux/Clients/selectors";
import { actions, reducer, sliceKey } from "../../shared-redux/Clients/slice";
import './styles.less';
import ClientsDropdown from '../../components/ClientsDropdown';
import { Link } from "react-router-dom";
import { reducer as signedReducer, sliceKey as signedSliceKey, actions as signedActions } from './../../shared-redux/SignedContract/slice';
import { signedContractSaga } from './../../shared-redux/SignedContract/saga';

const rowKey = '_id';

export const AdminSubscribersPermissions = (props) => {

    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: clientsSaga });

    useInjectReducer({ key: signedSliceKey, reducer: signedReducer });
    useInjectSaga({ key: signedSliceKey, saga: signedContractSaga });

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [displayLength, setDisplayLength] = useState(10);
    const [page, setPage] = useState(1);

    // filters
    const [userId, setUserId] = useState();
    const [requestType, setRequestType] = useState();
    const [status, setStatus] = useState();
    const { client, subscribers } = useSelector(selectClients);
    const dispatch = useDispatch();

    useEffect(() => {
       
        dispatch({
            type: actions.fetchSubscribers.type,
            payload: {
                filters: {
                    page,
                    limit: displayLength,
                    userId,
                    requestType,
                    status,
                }
            }
        });

    }, [page, displayLength, userId, requestType, status]);

    const handleChangePage = (dataKey) => {
        setPage(dataKey);
    }

    const handleChangeLength = (dataKey) => {
        setPage(1);
        setDisplayLength(dataKey);
    }

    const onSavePermission = (user, permissions) => {

    }

    // const onShow = (rowData) => {
    //     setIsPermissionModalVisible(true)
    //     setUserToEdit(rowData)
    // }

    // const onClose = () => {
    //     setIsPermissionModalVisible(false)
    // }

    const onStatusUpdate = (id, isCustomReportActive) => {
        dispatch({
            type: actions.updateClient.type,
            payload: {
                id: id,
                data: {
                    isCustomReportActive: !isCustomReportActive,
                }
            }
        });
        setTimeout(() => {
            window.location.reload()
        }, 500)
    }

    const { Column, Cell, HeaderCell } = Table;

    return (
        <Layout location={props.location} history={props.history}>
            <Helmet>
                <title>Subscribers</title>
            </Helmet>
            
            <Row className="mb3">
                <Col md={4} className="pl-0">
                    <ClientsDropdown
                        noLabel
                        onClean={() => { setUserId(null); setPage(1) }}
                        onChange={(value) => { setUserId(value); setPage(1) }}
                    />
                </Col>
               
                <Col md={5}>
                    <SelectInput
                        inputOptions={{
                            onClean: () => { setStatus(null); setPage(1) },
                            onChange: (value) => { setStatus(value); setPage(1) },
                            placeholder: "Subscriber Status",
                            data: [{ label: 'Active', value: 'ACTIVE' }, { label: 'Inactive', value: 'INACTIVE' }]
                        }}
                    />
                </Col>
                <Col md={8}>
                    <SelectInputNoSearch
                        noLabel
                        label="Type"
                        // stackedLabel
                        inputOptions={{
                            menuStyle: { fontSize: '12px' },
                            size: "xs",
                            placeholder: 'Report Type',
                            cleanable: false,
                            data: 
                                [
                                {
                                    "label": "Document Signature Progress",
                                    "value": "Document Signature Progress"
                                }
                            ],
                            value: "Document Signature Progress",
                            labelKey: "label",
                            valueKey: "value",
                            onChange: (value) => {
                                // setDocumentStatus(value)
                                // setPage(1)
                            },
                        }}
                    />
                    </Col>
                {/* <Col md={5}>
                    <SelectInput
                        inputOptions={{ placeholder: "More Recent Action" }}
                    />
                </Col>
                <Col md={5} className="pr-0">
                    <SelectInput
                        inputOptions={{ placeholder: "Permission Level" }}
                    />
                </Col> */}
            </Row>
            <Panel className="page-content-panel">
                <Table
                    className="subscribers-wrapper"
                    autoHeight
                    headerHeight={60}
                    data={subscribers.results ? subscribers.results : []}
                    rowKey={rowKey}
                    expandedRowKeys={expandedRowKeys}
                    
                    rowExpandedHeight={200}
                >
                   
                    <Column flexGrow={1.5}>
                        <HeaderCell>Subscriber <Icon icon="people-group" size="1x" /></HeaderCell>
                        <Cell dataKey="name">
                            {
                                rowData => <Link to={`/admin/clients/${rowData._id}`}>{rowData.name}</Link>
                            }
                        </Cell>
                    </Column>

                    <Column flexGrow={2}>
                        <HeaderCell>Email</HeaderCell>
                        <Cell dataKey="email">
                        </Cell>
                    </Column>

                    {/* <Column flexGrow={1} verticalAlign="middle" align="center">
                        <HeaderCell>Edit Permissions</HeaderCell>
                        <Cell>
                            {rowData =>  <IconButton size="sm" onClick={() => onShow(rowData)} icon={<Icon icon="pencil" size="2x" />} circle className="text-color-white color-tertiary mr2" />}
                        </Cell>
                    </Column> */}

                    <Column flexGrow={1} verticalAlign="middle" align="center">
                        <HeaderCell>Custom Report Permission</HeaderCell>
                        <Cell>
                            {
                                rowData =>
                                    <Toggle onChange={(checked) => onStatusUpdate(rowData.id, rowData.isCustomReportActive)} checked={rowData.isCustomReportActive} />
                            }
                        </Cell>
                    </Column>

                 </Table>
                <TablePagination
                    lengthMenu={[
                        {
                            value: 10,
                            label: 10
                        },
                        {
                            value: 20,
                            label: 20
                        }
                    ]}
                    activePage={page}
                    displayLength={displayLength}
                    total={subscribers.totalResults}
                    onChangePage={handleChangePage}
                    onChangeLength={handleChangeLength}
                />
            </Panel>
            {/* <EditPermissionModal
                isVisible={isPermissionModalVisible}
                // role={role}
                onSave={onSavePermission}
                onClose={onClose}
                user={userToEdit}
            /> */}
        </Layout>
    );
};
