import { useCallback, useEffect, useState } from "react";
import { Button, Col, Icon, IconButton, Modal, Row, Table, Radio, FlexboxGrid } from "rsuite";
import SquareButton from "../SquareButton";
import TextInput from "../TextInput";
import _ from "lodash";
import Toast from "../../../services/toastService";
import '../../styles/tables.less';

const { Column, Cell, HeaderCell } = Table;

const SwitchSubscriberModal = (props) => {

  const [title, setTitle] = useState("");
  const [selectedParent, setSelectedParent] = useState('')

  useEffect(() => {
    setTitle(props.title)
    setSelectedParent(props.currentParent)
  }, [props])

  const onSave = () => {
    // if (!title || title === "") {
    //   Toast.error("Please add Template Name");
    //   return;
    // }
    props.onSave(selectedParent);
  };

  const onClose = () => {
    props.onClose();
  }

  return (
    <Modal size="sm" show={props.isVisible} onHide={onClose} keyboard={false}>
      <Modal.Header>
        <Modal.Title>{"Switch Subscriber"}</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ marginTop: "0px" }}>
        <div >
          {
            (props.subscribers && props.subscribers.length < 2) ?
              <div style={{marginTop: 20}}>You are only associated with one LightHub Account.</div>
              :
              // <div>Show subscriber list</div>
              //   <Table
              //   // affixHorizontalScrollbar
              //   data={props.subscribers}

              //   className="admin-list-table"
              //   style={{ marginTop: 10, fontSize: 14 }}
              // >
              //   <Column flexGrow={1} verticalAlign="middle" align="left">
              //     <HeaderCell style={{ fontSize: 14 }}>Accounts</HeaderCell>
              //     <Cell>
              //     {
              //         (rowData, index) => {
              //           return rowData.organisationName ? rowData.organisationName : rowData.name
              //         }
              //     }
              //     </Cell>
              //   </Column>

              //   <Column flexGrow={1} verticalAlign="middle" align="left">
              //     <HeaderCell style={{ fontSize: 14 }}>Current Account</HeaderCell>
              //     <Cell className="">
              //       {
              //         (rowData, index) => {
              //           return (
              //             <span className="d-flex" >
              //               <Radio
              //                 checked={rowData.id === selectedParent}
              //                 onChange={(value, checked, event) => {
              //                   if(checked){
              //                     setSelectedParent(rowData.id)
              //                   }
              //                  }}
              //               />
              //             </span>
              //           );
              //         }
              //       }
              //     </Cell>
              //   </Column>
              // </Table>

              <div className="table-wrapper">
                <div className="table-container">
                  <Row style={{ margin: "20px 0px", fontWeight: 700 }}>

                    <Col md={12}>
                      <span style={{ padding: "20px 5px 20px 0px" }}> Accounts </span>
                    </Col>
                    <Col md={12} >
                      <span>  Current Account </span>
                    </Col>
                  </Row>
                  {
                    props.subscribers.map((user) => {
                    return <Row md = {24}  style={{ borderBottom: "1px solid #f2f2f5" }}>
                      <Col md={12} align="left">
                        <span style={{ padding: "10px 5px 10px 5px" , verticalAlign: 'middle'}} >{user.organisationName ? user.organisationName : user.name} </span>
                      </Col>
                      <Col md={12} align="left">
                        <Radio
                           checked={user.id === selectedParent}
                          onChange={(value, checked, event) => {
                            if (checked) {
                              setSelectedParent(user.id)
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  })}


                </div>
              </div>



          }
          {/* <SquareButton onClick={onClose} secondary className="mr2">
            Cancel
          </SquareButton> */}
          {/* <Col style={{marginTop:"1rem"}} flexGrow={1} verticalAlign="middle" align="right">
            <SquareButton onClick={onSave}>Save</SquareButton>
          </Col> */}

      </div>
        {/* <Modal.Header>
          <Modal.Title>{"Set Default Subscriber"}</Modal.Title>
        </Modal.Header>
        <div >
          {
            <Table
              // affixHorizontalScrollbar
              data={props.subscribers}

              className="admin-list-table"
              style={{ marginTop: 10, fontSize: 14 }}
            >
              <Column flexGrow={1} verticalAlign="middle" align="left">
                <HeaderCell style={{ fontSize: 14 }}>Subscribers</HeaderCell>
                <Cell dataKey="name" />
              </Column>

              <Column flexGrow={1} verticalAlign="middle" align="left">
                <HeaderCell style={{ fontSize: 14 }}>Current Default Subscriber</HeaderCell>
                <Cell className="">
                  {
                    (rowData, index) => {
                      return (
                        <span className="d-flex" >
                          <Radio
                            checked={rowData.isActive}
                            onChange={() => { }}
                          />
                        </span>
                      );
                    }
                  }
                </Cell>
              </Column>
            </Table>
          }
        </div> */}
      </Modal.Body>
      <Modal.Footer>

        <SquareButton onClick={onSave}>Save</SquareButton>
      </Modal.Footer>
    </Modal>
  );
};

SwitchSubscriberModal.defaultProps = {
  //title: "Template Name...",
  isVisible: true,
  onClose: () => { },
  onSave: (role) => { },
};

export default SwitchSubscriberModal;
