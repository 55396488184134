import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import {  request } from '../../../utils/request';
import _ from 'lodash';
import queryString from 'query-string';
//time log 

export function* fetchTimeLogList(action) {
  // if (!action.payload.type) {
    yield put(actions.timeLogListLoading());
  // }
  const { page } = action.payload;

  const filterQueryParams = { ...action.payload.filterQueryParams };
  Object.assign(filterQueryParams);
  const queryStringed = queryString.stringify(_.pickBy(filterQueryParams, _.identity));
  try {
    const data = yield call(
      request,
      `timelog/report?${queryStringed}&page=${page ? page : 1}`,
      {
        method: 'GET',
      }
    );
    const finalResult = {
      data: data.result ? data.result : [],
      type: action.payload.type ? action.payload.type : ''
    }

    //console.log(finalResult)
    yield put(actions.timeLogListSuccess(finalResult));
    if (action.payload.successCallback) {
      action.payload.successCallback(finalResult.data)
    }
  } catch (e) {
    yield put(actions.timeLogListFailure(e.toString()));
    console.log("e ==", e)
    //Toast.error(`Some error occurred while getting signed contracts ${e.toString()}`);
  }
}

export function* timeLogSaga() {

  yield takeLatest(actions.fetchTimeLogList.type, fetchTimeLogList);

}
