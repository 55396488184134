/**
 *
 * Asynchronously loads the component for AdminSubscribers
 *
 */

 import { lazyLoad } from '../../../utils/loadable';

 export const ConsolidatedInstance = lazyLoad(
   () => import('./index'),
   module => module.ConsolidatedInstance,
 );
 