import { useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "../../../utils/redux-injectors";
import { reducer, sliceKey, actions } from './../../shared-redux/Clients/slice';
import { selectClients } from './../../shared-redux/Clients/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { clientsSaga } from '../../shared-redux/Clients/saga';
import SelectInput from "../SelectInput";

const ClientsDropdown = (props) => {

    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: clientsSaga });
    // clients selector
    const { clients, stackedLabel, label, noLabel, inlineLabel } = useSelector(selectClients);

    // client list to populate clients dropdown
    const [clientList, setClientList] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: actions.fetchClients.type,
        });
    }, []);

    useEffect(() => {
        const list = clients.map((client) => {
            return { value: client.id, label: `${client.name} (${client.email})` }
        });
        setClientList(list);
    }, [clients]);

    const onSelectChange = (value) => {
        props.onChange(value);
    };

    const onClean = () => {
        props.onClean();
    };

    return (
        <SelectInput
            label={label}
            stackedLabel={stackedLabel}
            inlineLabel={inlineLabel}
            noLabel={noLabel}
            inputOptions={{
                data: clientList,
                placeholder: 'Select Client',
                onChange: onSelectChange,
                onClean: onClean,
            }}
        />
    );
};

ClientsDropdown.defaultProps = {
    onChange: () => { },
    onClean: () => { },
};

export default ClientsDropdown;