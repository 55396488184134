import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state) => state.timeLog || initialState;

export const selectTimeLog = createSelector(
  [selectDomain],
  timeLogState => timeLogState,
);
