import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state) => state.legalRequest || initialState;

export const selectLegalRequest = createSelector(
  [selectDomain],
  legalRequestState => legalRequestState,
);
