/**
 *
 * Asynchronously loads the component for ManageDeletedMatter
 *
 */
import { lazyLoad } from '../../../utils/loadable';

export const ManageDeletedMatters = lazyLoad(
    () => import('./index'),
    module => module.ManageDeletedMatters,
  
);