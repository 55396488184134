import _ from 'lodash';
import { createSlice } from '../../../utils/@reduxjs/toolkit';

// The initial state of the Payment container
export const initialState = {
  isLoading: false,
  success: false,
  error: '',
  
  totalResults: {},
  
  timeLogList:{}
};

const timeLogSlice = createSlice({
  name: 'timeLog',
  initialState,
  reducers: {

    
    //fetch time log

    fetchTimeLogList() { },
    timeLogListLoading(state, action) {
      state.isLoading = true;
    },
    timeLogListSuccess(state, action) {

      if (action.payload.type == 'total') {
        //console.log("In slice conndition==>", action.payload)
        state.totalResults = action.payload.data
      } else {
        state.isLoading = false;
        state.timeLogList = action.payload.data
      }
      state.error = ''
    },
    timeLogListFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload
    },
  },
});

export const { actions, reducer, name: sliceKey } = timeLogSlice;
