/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { createReducer } from './reducers';
import loadingMiddleware from './../services/LoaderService';
import { actions } from '../app/shared-redux/Clients/slice';
import { actions as hoWToActions } from '../app/shared-redux/HowTo/slice';
import { actions as clientResourcesActions } from '../app/shared-redux/ClientResources/slice';
import { actions as  formBuilderActions} from '../app/shared-redux/FormBuilder/slice';
import { actions as legalRequestActions } from '../app/shared-redux/LegalRequest/slice';
import { actions as userActions } from '../app/shared-redux/User/slice';

export function configureAppStore() {

  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  const loaderMiddleware = loadingMiddleware({
    promiseTypeSuffixes: ['Loading', 'Success', 'Failure|Error'],
  });

  // Create the store with saga middleware
  const middlewares = [
    sagaMiddleware,
    loaderMiddleware,
  ];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER,
            actions.updateClientImage.type,
            hoWToActions.saveHowTo.type,
            hoWToActions.updateHowTo.type,
            clientResourcesActions.uploadMedia.type,
            clientResourcesActions.deleteMedia.type,
            formBuilderActions.updateForm.type,
            legalRequestActions.inviteSubUser.type,
            legalRequestActions.saveRequest.type,
            legalRequestActions.assignLegalRequest.type,
            legalRequestActions.deleteArtefact.type,
            userActions.fetchSubscriberList.type,
          ],
        },
      }),
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }
  const persistor = persistStore(store);
  return { store, persistor };
}
