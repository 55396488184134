export const config = {
    paths : {
        frontUrl: 'https://beta.lighthub.law/',
        apiBaseUrl: 'https://beta.lighthub.law/api/v1/',
        serverPublicUrl: 'https://beta.lighthub.law/api/public/', 
    },
    keys:{
        azureClient : 'b8e21cc5-a90a-4444-b8ba-e9d136725577',
        cexClient : '6139975027',
        stripePK : 'pk_test_MyOpxkDPMMuiFZkGCqT42M4O'
    }
}