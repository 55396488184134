import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { request } from '../../../utils/request';
import Toast from '../../../services/toastService';
import userService from '../../../services/userService';
import queryString from 'query-string';
import _ from 'lodash';
import { saveTheme } from '../../../services/themeService';
import { isAdmin } from '../../../services/authService';
import paths from '../../../config/paths';

export function* fetchClients(action) {
  yield put(actions.clientsLoading());

  try {
    const data = yield call(
      request,
      'users/clients',
    );
    yield put(actions.clientsSuccess(data.clients));
  } catch (e) {
    yield put(actions.clientsFailure(e.toString()));
    Toast.error('Some error occurred while getting clients');
  }
}

export function* fetchSubscribers(action) {
  yield put(actions.subscribersLoading());
  const queryStringed = queryString.stringify(_.pickBy(action.payload.filters, _.identity));
  try {
    const data = yield call(
      request,
      `users/subscribers?${queryStringed}`,
    );
    yield put(actions.subscribersSuccess(data.subscribers));
  } catch (e) {
    yield put(actions.subscribersFailure(e.toString()));
    Toast.error('Some error occurred while getting subscribers');
  }
}

export function* saveSubscriber(action) {
  yield put(actions.saveSubscriberLoading());

  try {
    const data = yield call(
      request,
      `users/subscriber`,
      {
        method: 'POST',
        data: action.payload.data,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${Math.random().toString().substr(2)}`,
        }
      }
    );
    // console.log("api response ==", data)
    yield put(actions.saveSubscriberSuccess(data.client));
  } catch (e) {
    yield put(actions.saveSubscriberFailure(e.toString()));
  }

}

export function* fetchClient(action) {
  yield put(actions.clientLoading());

  try {
    const data = yield call(
      request,
      `users/client/${action.payload.id}`,
    );
    const currentUserId = yield call(userService.getCurrentUserId);
    if (currentUserId && currentUserId === action.payload.id) {
      yield call(userService.saveUser, data.client);
    }
    yield put(actions.fetchClientRolesSuccess(data.client.roles));
    yield put(actions.clientSuccess(data.client));
  } catch (e) {
    yield put(actions.clientFailure(e.toString()));
    Toast.error('Some error occurred while getting clients');
  }
}

export function* saveClient(action) {
  yield put(actions.saveClientLoading());

  try {
    const data = yield call(
      request,
      `users/subuser`,
      {
        method: 'post',
        data: action.payload.data,
      },
    );
    yield put(actions.saveClientSuccess(data.client));
    Toast.success('Successfully saved client details');
  } catch (e) {
    yield put(actions.saveClientFailure(e.toString()));
    Toast.error('Some error occurred while saving client details');
  }
}

export function* getSubUsersByParentId(action) {
  yield put(actions.getSubUsersByParentIdLoading());

  try {
    const data = yield call(
      request,
      `users/subuser/${action.payload.parentId}`,
    );
    yield put(actions.getSubUsersByParentIdSuccess(data.users));
  } catch (e) {
    yield put(actions.getSubUsersByParentIdFailure(e.toString()));
    Toast.error('Some error occurred while getting subusers');
  }
}

export function* getSubuserWithParent() {
  //yield put(actions.getSubUsersWithParentLoading());

  try {
    const data = yield call(
      request,
      `users/all-subuser`,
    );
    yield put(actions.getSubUsersWithParentSuccess(data.users));
  } catch (e) {
    yield put(actions.getSubUsersWithParentFailure(e.toString()));
    Toast.error('Some error occurred while getting subusers');
  }
}

export function* updateClient(action) {
  yield put(actions.updateClientLoading());

  try {
    const data = yield call(
      request,
      `users/${action.payload.id}`,
      {
        method: 'patch',
        data: action.payload.data,
      },
    );
    yield put(actions.updateClientSuccess(data.client));

    const themeData = {
      primaryColor: data.client.branding.primaryColor,
      secondaryColor: data.client.branding.secondaryColor,
      textColor: data.client.branding.textColor,
      logo: data.client.branding.logo.image !== "" ? `${paths.serverPublicUrl}${data.client.branding.logo.path}${data.client.branding.logo.image}` : null,
      favicon: data.client.branding.favicon.image !== "" ? `${paths.serverPublicUrl}${data.client.branding.favicon.path}${data.client.branding.favicon.image}` : null,
    };

    const isUserAdmin = yield call(isAdmin);

    // apply theme
    if (!isUserAdmin) {
      yield call(saveTheme, 'theme', themeData);
    }

    Toast.success('Successfully updated client details');
    const currentUserId = yield call(userService.getCurrentUserId);
    if (currentUserId && currentUserId === action.payload.id) {
      yield call(userService.saveUser, data.client);
    }

  } catch (e) {
    yield put(actions.updateClientFailure(e.toString()));
    Toast.error('Some error occurred while updating client details');
  }
}

export function* deleteSubUser(action) {
  yield put(actions.deleteSubUserLoading());

  try {
    const data = yield call(
      request,
      `users/${action.payload.id}`,
      {
        method: 'delete',
        data: action.payload.data,
      },
    );
    yield put(actions.deleteSubUserSuccess());

  } catch (e) {
    console.log(e);
    yield put(actions.deleteSubUserFailure(e.toString()));
    Toast.error('Some error occurred while updating client details');
  }
}

export function* completeClientCustomization(action) {
  yield put(actions.updateClientLoading());

  try {
    const data = yield call(
      request,
      `users/${action.payload.id}`,
      {
        method: 'patch',
        data: action.payload.data,
      },
    );
    yield put(actions.updateClientSuccess(data.client));
    Toast.success('Successfully updated client details');
    const currentUserId = yield call(userService.getCurrentUserId);
    if (currentUserId && currentUserId === action.payload.id) {
      yield call(userService.saveUser, data.client);
    }
    window.location.href = '/';
  } catch (e) {
    console.log(e);
    yield put(actions.updateClientFailure(e.toString()));
    Toast.error('Some error occurred while updating client details');
  }
}

export function* updateSubusers(action) {
  yield put(actions.updateSubusersLoading());

  try {
    const data = yield call(
      request,
      `users/upsert`,
      {
        method: 'patch',
        data: action.payload.data,
      },
    );
    yield put(actions.updateSubusersSuccess(data.users));
    action.payload.successCallback()
    Toast.success('Successfully updated subusers');
  } catch (e) {
    yield put(actions.updateSubusersFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      if (e.response.data.users) {
        yield put(actions.updateSubusersSuccess(e.response.data.users));
      }
      return;
    }
    Toast.error(e.message);
  }
}

export function* resendInvite(action) {
  yield put(actions.resendInviteLoading());

  try {
    const data = yield call(
      request,
      `users/resend-invite`,
      {
        method: 'post',
        data: action.payload.data,
      },
    );
    yield put(actions.resendInviteSuccess());
    Toast.success('Successfully resent invite');
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.resendInviteFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
    }
    Toast.error(e.message);
  }
}

export function* updateClientLawyers(action) {
  yield put(actions.updateClientLawyersLoading());

  try {
    const data = yield call(
      request,
      `users/toggle-is-lawyer`,
      {
        method: 'post',
        data: action.payload.data,
      },
    );
    yield put(actions.updateClientLawyersSuccess(data));
    if (action.payload.successCallback) {
      yield call(action.payload.successCallback)
    }

    Toast.success('Successfully sent email ');
  } catch (e) {
    yield put(actions.updateClientLawyersFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
    }
    Toast.error(e.message);
  }
}

export function* updateClientImage(action) {
  yield put(actions.updateClientImageLoading());

  try {
    const data = yield call(
      request,
      `users/clientImage/${action.payload.id}`,
      {
        method: 'patch',
        data: action.payload.data,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${Math.random().toString().substr(2)}`,
        }
      },
    );
    yield put(actions.updateClientImageSuccess(data.client));

    const themeData = {
      primaryColor: data.client.branding.primaryColor,
      secondaryColor: data.client.branding.secondaryColor,
      textColor: data.client.branding.textColor,
      logo: data.client.branding.logo.image !== "" ? `${paths.serverPublicUrl}${data.client.branding.logo.path}${data.client.branding.logo.image}` : null,
      favicon: data.client.branding.favicon.image !== "" ? `${paths.serverPublicUrl}${data.client.branding.favicon.path}${data.client.branding.favicon.image}` : null,
    };

    const isUserAdmin = yield call(isAdmin);

    // apply theme
    if (!isUserAdmin) {
      yield call(saveTheme, 'theme', themeData);
    }

    Toast.success(`Successfully updated ${action.payload.type}`);
  } catch (e) {
    yield put(actions.updateClientImageFailure(e.toString()));
    Toast.error('Some error occurred while uploading file');
  }
}

export function* unSelectClient() {
  yield put(actions.unSelectClientSuccess());
}

export function* getPermissions(action) {
  // yield put(actions.getPermissionsLoading());

  try {
    const data = yield call(
      request,
      `role/permissions`,
    );
    yield put(actions.getPermissionsSuccess(data.permissions));
  } catch (e) {
    yield put(actions.getPermissionsFailure(e.toString()));
    Toast.error(`Some error occurred while getting permmissions: ${e.message}`);
  }
}

export function* createRole(action) {
  yield put(actions.createRoleLoading());

  try {
    const data = yield call(
      request,
      `role`,
      {
        method: "POST",
        data: action.payload.data,
      }
    );
    yield put(actions.createRoleSuccess(data.role));
    Toast.success('Successfully created new role');
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.createRoleFailure(e.toString()));
    Toast.error('Some error occurred while saving role');
  }
}

export function* updateRole(action) {
  yield put(actions.updateRoleLoading());

  try {
    const data = yield call(
      request,
      `role/${action.payload.roleId}`,
      {
        method: 'PATCH',
        data: action.payload.data,
      }
    );
    yield put(actions.updateRoleSuccess(data));

    Toast.success('Successfully Updated role');
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.updateRoleFailure(e.toString()));
    Toast.error('Some error occurred while updating role');
  }
}

export function* deleteRole(action) {
  yield put(actions.deleteRoleLoading());

  try {
    yield call(
      request,
      `role/${action.payload.roleId}`,
      {
        method: 'DELETE',
      }
    );
    yield put(actions.deleteRoleSuccess(action.payload.roleId));

    Toast.success('Successfully delete role');
  } catch (e) {
    yield put(actions.deleteRoleFailure(e.toString()));
    Toast.error('Some error occurred while deleting role');
  }
}

export function* fetchPaymentInfo(action) {
  yield put(actions.fetchPaymentInfoLoading());

  try {
    const data = yield call(
      request,
      `users/payment-methods`,
    );
    yield put(actions.fetchPaymentInfoSuccess(data.details));

  } catch (e) {
    yield put(actions.fetchPaymentInfoFailure(e.toString()));
    Toast.error('Some error occurred while getting payment method');
  }
}

export function* fetchTransactions(action) {
  yield put(actions.fetchTransactionsLoading());

  try {
    const data = yield call(
      request,
      `subscription/invoices`,
    );
    yield put(actions.fetchTransactionsSuccess(data.invoices));

  } catch (e) {
    yield put(actions.fetchTransactionsFailure(e.toString()));
    Toast.error('Some error occurred while getting payment method');
  }
}

export function* fetchCExTemplates(action) {
  try {
    const data = yield call(
      request,
      `users/my-cex-templates`,
    );
    yield put(actions.fetchCExTemplatesSuccess(data.templates));

  } catch (e) {
    yield put(actions.fetchCExTemplatesFailure(e.toString()));
    Toast.error('Some error occurred while getting payment method');
  }
}

export function* fetchBUNames(action) {
  yield put(actions.fetchBUNamesLoading());

  try {
    const data = yield call(
      request,
      `bu`,
    );
    yield put(actions.fetchBUNamesSuccess());
    action.payload.successCallback(data.result);

  } catch (e) {
    yield put(actions.fetchBUNamesFailure(e.toString()));
    Toast.error('Some error occurred while fetching data');
  }
}

export function* createNewBUName(action) {
  yield put(actions.createNewBUNameLoading());

  try {
    const data = yield call(
      request,
      `bu`,
      {
        method: "POST",
        data: action.payload.data,
      }
    );
    yield put(actions.createNewBUNameSuccess(data.role));
    Toast.success('Successfully created new business unit');
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.createNewBUNameFailure(e.toString()));
    Toast.error('Some error occurred while creating unit');
  }
}


export function* editBusinessUnit(action) {
  yield put(actions.editBusinessUnitLoading());

  try {
    const data = yield call(
      request,
      `bu/${action.payload.id}`,
      {
        method: "PUT",
        data: action.payload.data,
      }
    );
    yield put(actions.editBusinessUnitSuccess(data.role));
    Toast.success('Successfully edited business unit');
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.editBusinessUnitFailure(e.toString()));
    Toast.error('Some error occurred while editing unit');
  }
}

export function* deleteBusinessUnit(action) {
  yield put(actions.deleteBusinessUnitLoading());

  try {
    yield call(
      request,
      `bu/${action.payload.id}`,
      {
        method: 'DELETE',
      }
    );
    yield put(actions.deleteBusinessUnitSuccess(action.payload.roleId));

    Toast.success('Successfully delete business unit');
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.deleteBusinessUnitFailure(e.toString()));
    Toast.error('Some error occurred while deleting business unit');
  }
}

export function* clientsSaga() {
  yield takeLatest(actions.fetchClients.type, fetchClients);
  yield takeLatest(actions.fetchSubscribers.type, fetchSubscribers);
  yield takeLatest(actions.fetchClient.type, fetchClient);
  yield takeLatest(actions.saveClient.type, saveClient);
  yield takeLatest(actions.saveSubscriber.type, saveSubscriber);
  yield takeLatest(actions.updateClient.type, updateClient);
  yield takeLatest(actions.completeClientCustomization.type, completeClientCustomization);
  yield takeLatest(actions.updateSubusers.type, updateSubusers);
  yield takeLatest(actions.updateClientImage.type, updateClientImage);
  yield takeLatest(actions.unSelectClient.type, unSelectClient);
  yield takeLatest(actions.getPermissions.type, getPermissions);
  yield takeLatest(actions.createRole.type, createRole);
  yield takeLatest(actions.updateRole.type, updateRole);
  yield takeLatest(actions.deleteRole.type, deleteRole);
  yield takeLatest(actions.getSubUsersByParentId.type, getSubUsersByParentId);
  yield takeLatest(actions.getSubUsersWithParent.type, getSubuserWithParent);
  yield takeLatest(actions.resendInvite.type, resendInvite);
  yield takeLatest(actions.fetchPaymentInfo.type, fetchPaymentInfo);
  yield takeLatest(actions.fetchTransactions.type, fetchTransactions);
  yield takeLatest(actions.fetchCExTemplatesLoading.type, fetchCExTemplates);
  yield takeLatest(actions.updateClientLawyers.type, updateClientLawyers);
  yield takeLatest(actions.createNewBUName.type, createNewBUName);
  yield takeLatest(actions.fetchBUNames.type, fetchBUNames);
  yield takeLatest(actions.deleteBusinessUnit.type, deleteBusinessUnit);
  yield takeLatest(actions.editBusinessUnit.type, editBusinessUnit);
}
